export const styles = () => {
  return {
    tableHeader: {
      backgroundColor: '#F1FAFC !important',
      fontSize: 13,
      color: '#114D91 !important',
    },
    tableCellRelative: {
      position: 'relative',
    },
    trashButton: {
      position: 'absolute',
      left: '10%',
      top: 12,
    },
    modalMessage: {
      marginTop: 10,
    },
    modalButton: {
      borderRadius: 3,
      '& + &': {
        marginLeft: '10px',
      },
    },
    modalSection: {
      margin: [12, 0, 36, 0],
    },
    labelHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      marginBottom: 12,
    },
    tooltipIcon: {
      marginLeft: 5,
    },
    tooltipList: {
      marginLeft: 10,
      listStyleType: 'disc',
    },
    hideFormControlLabel:{
      fontSize: '0px !important',
      color: '#114D91 !important',
      border: '0px',
      height: '1px', 
      margin: '0px -1px -1px 0px', 
      overflow: 'hidden',
      padding: '0px', 
      position: 'absolute', 
      width: '1px',
    },
  };
};
